import React from "react";
import { graphql, Link } from "gatsby"

import Layout from "../templates/Layout"

import * as styles from "./index.module.css"

export const pageQuery = graphql`
    query Homepage {
        site {
            siteMetadata {
                name
            }
        }
        allMdx(
            filter: {slug: {in: ["ju-jitsu", "gallery", "contact-us"]}}
            sort: {fields: frontmatter___order, order: ASC}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        summary
                        cta
                    }
                    slug
                }
            }
        }
    }
`


class Homepage extends React.Component {
    render() {
        const data = this.props.data;

        return (
            <Layout
                title={data.site.siteMetadata.name}
                location={this.props.location}
                isHome
            >

                <p>Blep</p>
            </Layout>
        )
    }
}

export default Homepage

